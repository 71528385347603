<template>
  <v-dialog v-model="dialog" class="dialog" max-width="450" persistent @click:outside="handleCloseModal"
    @keydown="CloseWithEsc">
    <v-card class="card" style="overflow: hidden">
      <v-progress-linear :active="loading" :indeterminate="loading" absolute color="primary accent-4"
        top></v-progress-linear>
      <v-row class="align-center px-0 mx-0 mb-2">
        <img :src="pivotAddLogo" alt="Pivotad log" width="165" height="50"/>
        <v-spacer></v-spacer>
        <v-btn icon @click="$emit('closeModal')">
          <v-icon color="black">mdi-close</v-icon>
        </v-btn>
      </v-row>
      <h3 class="mt-4 font-family-raleway-light" style="font-family: 'Raleway light', sans-serif">
        {{ $t("register_card.signup_for_afree_trial") }}
      </h3>
      <p class="mt-5 font-raleway-regular">
        {{ $t("register_card.start_you") }}
      </p>
      <v-form ref="form" v-model="valid" class="text-right form" lazy-validation>
        <v-text-field v-model="fullName" :rules="registeryRules"
          class="input-border mt-4 py-0 mb-0 mt-4 font-family-raleway-medium" height="50" outlined
          :placeholder="$t('register_card.fullname_input_text')" hide-details>
          <template v-slot:append>
            <v-btn v-if="fullName.length > 0" class="py-2 mr-2" height="30" icon @click="clearFull">
              <v-icon>mdi-close-circle-outline</v-icon>
            </v-btn>
            <img v-if="fullName.length > 0" :src="check" class="mt-1" height="20" width="20" loading="lazy"/>
          </template>
        </v-text-field>
        <v-text-field v-model="companyName" :rules="registeryRules"
          class="input-border py-0 mb-0 mt-4 font-family-raleway-medium" height="50" outlined
          :placeholder="$t('register_card.company_input_text')" hide-details>
          <template v-slot:append>
            <v-btn v-if="companyName.length > 0" class="py-2 mr-2" height="30" icon @click="clearName">
              <v-icon>mdi-close-circle-outline</v-icon>
            </v-btn>
            <span>
              <img v-if="companyName.length > 0" :src="check" class="mt-1" height="20" width="20" loading="lazy"/>
            </span>
          </template>
        </v-text-field>
        <v-text-field v-model="email" :rules="emailRules" class="input-border py-0 mb-0 mt-4 font-family-raleway-medium"
          height="50" outlined :placeholder="$t('register_card.email_input_text')" hide-details>
          <template v-slot:append>
            <v-btn v-if="email.length > 0" class="py-2 mr-2" height="30" icon @click="clearEmail">
              <v-icon>mdi-close-circle-outline</v-icon>
            </v-btn>
            <span>
              <img v-if="email.length > 0" :src="check" class="mt-1" height="20" width="20" loading="lazy"/>
            </span>
          </template>
        </v-text-field>
        <v-text-field v-model="password" :append-icon="pass ? 'mdi-eye' : 'mdi-eye-off'" :rules="registeryRules"
          :type="pass ? 'text' : 'password'" class="input-border mt-4 font-family-raleway-medium" height="50" outlined
          :placeholder="$t('register_card.password_input_text')" @click:append="pass = !pass" hide-details></v-text-field>
        <v-row class="pa-0 ma-0 mt-5 align-center">
          <div class="d-flex align-center">
            <v-simple-checkbox v-model="checkbox" color="indigo" class="align-self-baseline"></v-simple-checkbox>
            <div class="pb-0 mb-0 font-raleway-regular d-fl" style="white-space: normal; text-align: start">
              {{ $t("login_card.agree_terms_text") }}
              <a class="font-family-raleway-medium" href="/terms-of-use" target="_blank">{{
                $t("login_card.agree_terms_text_link") }} |
              </a>
              <a class="font-family-raleway-medium" href="/privacy-policy" target="_blank">{{
                $t("login_card.agree_policy_text_link") }}</a>
            </div>
          </div>
        </v-row>
        <v-btn class="button login-btn mt-7" height="60" @click="handleSubmit" :loading="loading">
          {{ $t("register") }}
        </v-btn>
        <p class="text-center mt-4 mb-0 pb-3 font-raleway-regular">
          {{ $t("register_card.already_registered_text") }}
          <a @click="handleChangeToLogin" class="font-family-raleway-medium">{{
            $t("login")
          }}</a>
        </p>
      </v-form>
    </v-card>
  </v-dialog>
</template>
<script>
import check from "@/assets/img/check.svg";
import axios from "axios";
import pivotAddLogo from "../../../assets/icon/Pivotadd_black.svg";

export default {
  name: "Registration",
  data: () => ({
    pivotAddLogo,
    valid: true,
    email: "",
    fullName: "",
    companyName: "",
    password: "",
    checkbox: false,
    check,
    pass: false,
    loading: false,
    registeryRules: [(v) => !!v || this.$t("required_text")],
    emailRules: [
      (v) => !!v || this.$t("required_text"),
      (v) => /.+@.+\..+/.test(v) || this.$t("email_must_valid"),
    ],
  }),

  props: {
    dialog: Boolean,
  },

  methods: {
    handleChangeToLogin() {
      this.clearName();
      this.clearFull();
      this.clearEmail();
      this.password = '';
      this.checkbox = false;
      this.$emit("closeModal");
      this.$emit("openSignIn");
    },
    handleCloseModal() {
      this.clearName();
      this.clearFull();
      this.clearEmail();
      this.password = '';
      this.checkbox = false;
      this.$emit("closeModal");
    },
    CloseWithEsc(event) {
      if (event.key === 'Escape') {
        this.clearName();
        this.clearFull();
        this.clearEmail();
        this.password = '';
        this.checkbox = false;
        this.$emit('closeModal');
      }
    },
    async handleSubmit() {
      await this.$refs.form.validate();
      if (this.valid) {
        const data = {
          fullName: this.fullName,
          companyName: this.companyName,
          email: this.email,
          password: this.password,
        };
        this.loading = true;
        try {
          const res = await axios.post(
            `${process.env.VUE_APP_BASE_URL}/users`,
            data
          );
          const userId = res?.data?.user.id;
          localStorage.setItem(
            "user",
            JSON.stringify({
              fullName: res?.data?.fullName,
              email: res?.data?.email,
            })
          );
          localStorage.setItem("userId", userId);
          localStorage.setItem("token", res?.data?.token);
          window.location.replace("/welcome");
        } catch (err) {
          const errCode = err?.response?.statusText;
          this.$notify({
            group: "foo",
            type: "error",
            title: errCode ? errCode : this.$t("something_wrong"),
          });
        }
        this.loading = false;
      }
    },
    clearFull() {
      this.fullName = "";
    },
    clearName() {
      this.companyName = "";
    },
    clearEmail() {
      this.email = "";
    },
  },
};
</script>
<style lang="scss" scoped>
.dialog {
  border-radius: 20px !important;
}

.card {
  width: 100% !important;
  border-radius: 20px !important;
  padding: 40px;
  @media screen and (max-width: 600px) {
    padding: 40px 20px 20px 20px!important;
  }
  @media screen and (max-width: 450px) {
    padding: 30px 15px 15px 15px!important;
  }
}

.login-btn {
  width: 100%;
  font-weight: bold;
}
</style>
